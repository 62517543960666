@import "variables.scss";

.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: $mujiBlue;
  }
  
  .rounded {
    border-radius: 0.8rem !important;
  }
  
  .carousel-indicators li {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: $mujiBlue;
  }
  
  .carousel-indicators {
    bottom: -80px;
  }
  
  .avatar {
    max-width: 90%;
  }
  
  @media (min-width: 576px) {
    .avatar {
      max-width: 50%;
    }
  }
  
  .box {
    border-radius: 12px;
    padding: 3.5rem 2rem;
    margin-top: -9rem;
    background-color: #293347;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #293347;
  }
  
  .bg-grey {
    background-color: #293347;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
  }
  
  .nav-shadow {
    -moz-box-shadow: 3px 0px 5px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 3px 0px 5px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 3px 0px 5px 3px rgba(0, 0, 0, 0.3);
  }
  
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    overflow: hidden;
    margin: auto; /* make em equal */
    width: 100%;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .fadeAnim {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0.5;
  }
  .fadeAnim:hover {
    opacity: 1;
  }
  
  .linkedin {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 1;
  }
  .linkedin:hover {
    opacity: 0.5;
  }
  
  .badge-primary {
    color: #fff;
    background-color:$mujiBlue;
    padding: 5px;
  }
  
  #header {
    margin-top: 80px;
  }
  
  #header h1 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 700;
    width: 90%;
  }
  
  @media (max-width: 576px) {
    #header h1 {
      font-size: 25px;
    }
  }
  
  #header h2 {
    font-weight: 300;
    font-size: 24px;
    width: 90%;
  }
  
  @media (max-width: 576px) {
    #header h2 {
      font-size: 18px;
    }
  }
  
  #bio {
    background-color: $mujiBlue;
    color: #ffffff;
    padding: 60px;
    margin-top: -1px;
    padding-bottom: 150px;
  }
  #bio div {
    width: 80%;
  }
  
  @media (max-width: 1024px) {
    #bio {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    #bio div {
      width: 90%;
    }
  }
  #bio h1 {
    font-size: 35px;
    margin-bottom: 30px;
    font-weight: 700;
  }
  
  #bio h2 {
    font-weight: 300;
    font-size: 20px;
  }
  
  #skills-section {
    background-color: #ffffff;
    border-radius: 25px;
    min-height: 400px;
    width: 90%;
    margin-top: -110px;
    padding: 30px;
    border-width: 1px;
    border-color: #e6ecf8;
    border-style: solid;
  }
  
  #skills-section h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  #skills-section p {
    font-weight: 300;
  }
  
  #skills-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 300;
  }
  
  #skills-section #subheading {
    color: $mujiBlue;
    font-weight: 400;
  }
  
  #mywork {
    margin-top: 110px;
  }
  
  #mywork h2 {
    font-weight: 700;
  }
  
  #collaboration {
    margin-top: 110px;
  }
  
  #collaboration h1 {
    font-weight: 700;
    width: 50%;
    margin-bottom: 50px;
  }
  
  @media (max-width: 1024px) {
    #collaboration h1 {
      font-size: 30px;
      width: 90%;
    }
  }
  
  .testimonials {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 50%;
    border-radius: 50%;
  }
  @media (min-width: 576px) {
    .testimonials {
      max-width: 30%;
    }
  }
  
  #testimonials {
    margin-top: 110px;
    margin-bottom: 170px;
  }
  
  #testimonials h1 {
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  #testimonials h4 {
    font-weight: 700;
  }
  
  #testimonials #carouselExampleIndicators {
    width: 60%;
    margin-top: 50px;
  }
  
  @media (max-width: 1024px) {
    #testimonials #carouselExampleIndicators {
      width: 90%;
    }
  }
  
  .footer {
    background-color: $mujiBlue;
    padding: 5rem 1.5rem;
    color: white;
  }
  
  #footer h1 {
    font-weight: 700;
    font-size: 24px;
  }
  
  .btn-outline-primary-footer {
    color: white;
    border-color: $mujiBlue;
  }
  .btn-outline-primary-footer:hover {
    color: white;
    background-color: $mujiBlue;
  }
  