.barBG {
  background-color: #e2e2e2;
  height: 34px;
  border-radius: 5px;
  margin-top: 10px;
  -webkit-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.08);
  box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.08);
}

.barFill {
  width: 0;
  height: 100%;
  background-color: #33acdf;
  text-align: left;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  -webkit-transition: width 1000ms ease-in-out;
  -o-transition: width 1000ms ease-in-out;
  transition: width 1000ms ease-in-out;
}
